@import '../../styles/global/variables';
@import '../../styles/global/mixins';

.autocomplete {
  position: relative;
  width: 100%;
  max-width: 24rem;
  display: none;

  @include breakpoint(large) {
    display: block;
    padding-left: $global-margin * 2;
    margin-left: $global-margin * 2;
    border-left: 0.0625rem solid map-get($grayscale-colors, gray-100);
  }
}

.autocomplete__input {
  position: relative;
  width: 100%;
  height: 3.5rem;
  padding: $global-margin 0;
  font-size: 1rem;
  font-weight: map-get($global-weight, semi);
  color: map-get($brand-colors, primary-highdark);
  border: none;
  border-bottom: 0.0625rem solid map-get($grayscale-colors, gray-100);
  appearance: none;
  outline: none;
  transition: $global-transition;
  cursor: pointer;

  &:hover {
    border-color: map-get($brand-colors, primary);
  }

  &::placeholder {
    color: map-get($brand-colors, primary-highdark);
  }
}

.autocomplete__submit {
  position: absolute;
  top: $global-margin;
  right: 0;
  border: none;
  padding: 0;
  cursor: pointer;
}

.autocomplete__dropdown {
  position: absolute;
  top: calc(100% - 0.0625rem);
  left: $global-margin * 2;
  width: 100%;
  min-width: 60rem;
  max-height: 30rem;
  padding-block:  $global-margin;
  background-color: map-get($grayscale-colors, white);
  border-radius: 0 0 $global-radius $global-radius;
  box-shadow: 0 2rem 5rem #00163D29;
  z-index: 8;
  overflow: auto;
  border-top: 0.0625rem solid map-get($grayscale-colors, gray-100);
}

.autocomplete__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.autocomplete__item {
  display: flex;
  align-items: center;
  padding: ($global-margin / 2) ($global-margin * 2);
  cursor: pointer;
  transition: $global-transition;

  &:hover {
    background-color: rgba(map-get($brand-colors, primary-200), 0.2);
  }

  + .autocomplete__item {
    border-top: 0.0625rem solid map-get($grayscale-colors, gray-100);
  }
}

.autocomplete__price {
  font-weight: map-get($global-weight, bold);
  font-size: 1.125rem;
  color: map-get($brand-colors, secondary);
}

.autocomplete__main {
  display: flex;
  align-items: center;
  flex: 1;

  h3 {
    font-size: 1rem;
  }

  img {
    border-radius: $global-radius / 2;
  }
}

.autocomplete__title {
  padding: $global-margin $global-margin * 2;
}