$gap: 0.75rem;

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.grid--center {
  justify-content: center;
}

.grid--end {
  justify-content: flex-end;
}

.grid--end\:md {
  @include breakpoint(medium) {
    justify-content: flex-end;
  }
}

.grid--middle {
  align-items: center;
}

.grid--bottom {
  align-items: flex-end;
}

.grid--justify {
  justify-content: space-between;
}

.grid--justify\:md {
  @include breakpoint(medium) {
    justify-content: space-between;
  }
}

.grid.gap {
  margin-inline: $gap * -1;
}
