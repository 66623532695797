@import '../../styles/global/variables';
@import '../../styles/global/mixins';

.header {
  position: sticky;
  top: 0;
  padding: $global-margin;
  box-shadow: $global-shadow;
  z-index: 40;
  background-color: map-get($grayscale-colors, white);

  @include breakpoint(large) {
    padding-block: $global-margin * 1.25;
  }
}

.navbar {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 auto;

  @include breakpoint(xlarge) {
    max-width: calc(100% - 8rem);
  }
}

.navbar__left,
.navbar__right {
  display: flex;
  align-items: center;
}

.navbar__left {
  flex: 1;
}

.navbar__right {
  flex: 0 0 auto;
}

.header__switch {
  display: inline-flex;
  position: absolute;
  bottom: -1.625rem;
  width: 11.625rem;
  left: 1rem;

  @include breakpoint(medium) {
    left: calc(50% - 7.875rem);
  }

  a {
    flex: 0 0 auto;
    padding: 0.25rem 0.5rem;
    border-radius: 0 0 $global-radius / 2 $global-radius / 2;
    background-color: map-get($brand-colors, primary-400);
    font-size: 0.75rem;

    &.is-active {
      background-color: map-get($brand-colors, secondary);
      font-size: 0.875rem;
    }
  }

  a + a {
    margin-left: 0.25rem;
  }
}
