.hide {
  display: none;
}

.hide\:lg {
  @include breakpoint(large) {
    display: none !important;
  }
}

.visible\:md {
  @include breakpoint(medium) {
    display: block !important;
  }  
}

.visible\:lg {
  @include breakpoint(large) {
    display: block !important;
  }
}