@import '../../../styles/global/variables.scss';
@import '../../../styles/global/mixins.scss';

.form__input {
	position: relative;
	height: 3rem;
	border: 0.0625rem solid map-get($grayscale-colors, gray-100);
	border-radius: 0.375rem;
	padding-inline: 3rem 1rem;
	margin-bottom: 1.5rem;
	cursor: pointer;

}

.form__label {
	color: map-get($grayscale-colors, black) !important;
  font-size: 0.875rem;
	line-height: 1.5;
}

.form__payment {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-block: $global-margin;
	border-block: 0.0625rem solid map-get($grayscale-colors, gray-100);
	flex-wrap: wrap;
}