table {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 1rem;
	border-radius: 0;

	td {
		border-bottom: 0.0625rem solid map-get($grayscale-colors, gray-200);
	}
}

.table--reservation {
	background-color: map-get($grayscale-colors, white);
	border: none;
	font-size: 1rem;
	text-align: left;

	.thead {
		font-weight: map-get($global-weight, semi);
	}

	th {
		border-bottom: 0.1875rem solid map-get($grayscale-colors, primary-highdark);
	}

	th,
	td {
		padding: 1rem 1.5rem;
	}
}

.table--compare {
	min-width: 50rem;
	border: 0.0625rem solid map-get($grayscale-colors, gray-200);

	td + td {
		border-left: 0.0625rem solid map-get($grayscale-colors, gray-200);
	}

	td {
		color: map-get($grayscale-colors, gray-900);
		font-size: 0.875rem;
		padding: 1rem 1.5rem;
		line-height: 1.25rem;
	}

	tr > td:first-child {
		color: map-get($grayscale-colors, white);
		font-weight: map-get($global-weight, semi);
		background-color: map-get($brand-colors, primary-medium);
		border-bottom-color: map-get($grayscale-colors, gray-800);
	}
}

.table-scroll {
	overflow-x: auto;
}
