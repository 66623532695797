.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
	font-size: 0.875rem;
  line-height: 1rem;
	font-weight: map-get($global-weight, bold);
  transition: $global-transition;
	border: 0.125rem solid transparent;
	border-radius: $global-radius * 1.5;
	padding: 0.875rem 2.5rem;
	text-decoration: none;
	letter-spacing: 0.03125rem;
	cursor: pointer;
}

.button--primary {
	color: map-get($grayscale-colors, white);
	background-color: map-get($brand-colors, primary);

	&:hover:not(.button--date) {
		background-color: map-get($brand-colors, primary-medium);
	}
}

.button.hollow {
	background-color: transparent;
	color: map-get($brand-colors, primary);
	border-color: map-get($brand-colors, primary);

	&:hover {
		color: map-get($grayscale-colors, white);
		background-color: map-get($brand-colors, primary);
	}
}

.button.hollow.hollow--light {
	border-color: map-get($grayscale-colors, white);
	color: map-get($grayscale-colors, white);

	&:hover {
		color: map-get($brand-colors, primary);
		background-color: map-get($grayscale-colors, white);
	}
}

.button--small {
	padding: 0.75rem $global-margin;
	font-size: 0.875rem;
}

.button--menu {
	padding: 0;
	color: map-get($brand-colors, primary-highdark);
}

.button--plain {
	padding: 0;
	margin: 0;
	background-color: transparent;
}

.button--full {
	width: 100%;
}

.button--underline {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.button--date {
	padding-inline: 1rem;
	font-size: 0.875rem;
}
