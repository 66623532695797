$breakpoints: (
  'small':  23.125rem,
  'medium': 47.5rem,
  'large':  63.75rem,
  'xlarge': 105rem,
) !default;

@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}