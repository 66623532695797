// Colors
$brand-colors: (
  primary: #0049C6,
  primary-100: #EDF4FF,
  primary-200: #DAE7FF,
  primary-highlight: #156CFF,
  primary-400: #146AFF,
  primary-semi: #0042B2,
  primary-medium: #002964,
  primary-dark: #00163D,
  primary-highdark: #2B374C,
  secondary: #C67D00,
  secondary-light: #F5F4F2,
);

$grayscale-colors: (
  white: #ffffff,
  gray-100: #E2E7EF,
  gray-200: #F1F3F5,
  gray-800: #374762,
  gray-900: #2B374C,
  black: #000816,
);

$red: #c60049;

// Globals
$global-font: 'Open Sans', sans-serif;
$global-width: 77rem;
$global-margin: 1rem;
$global-gradient: transparent linear-gradient(31deg, #00163D 0%, #4F5D7562 53%, #80808000 100%) 0% 0% no-repeat padding-box;
$global-radius: 1rem;
$global-transition: all 0.18s ease-in;
$global-shadow: 0 0 2rem #00163D29;
$global-border: 0.0625rem solid map-get($grayscale-colors, gray-100);

$global-weight: (
  regular: 400,
  semi: 600,
  bold: 700
);