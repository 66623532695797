.print-pdf {
  cursor: pointer;
}

.pdf-controls i {
  color: #4e4e4e !important;
  background-color: transparent;
  top: 0;
  left: 0;
  opacity: 1;
  right: 0;
  margin: 0;
  padding: 5px 4.5px;
  font-size: 22px;
}

.pdf-controls i:hover {
  color: #ecb700;
}

.catalogue-country {
  height: 50px;
  background-color: grey;
  color: white;
  text-align: center;
  display: table-cell;
  cursor: pointer;
  vertical-align: middle;
  border-bottom: white 1px solid;
  width: 250px;
}

.catalogue-country.visitor {
  background-color: #edb700;
  color: white;
}

.catalogue-country.employee {
  background-color: #afc836;
  color: white;
}

.catalogue-cms-wrapper {
  padding: 80px 15px 100px 15px;
  max-width: 970px;
  margin: 0 auto;
  line-height: 20px;
  color: #404040;
}

.catalogue-cms-wrapper img {
  width: 100%;
  height: auto;
}

.catalogue-wrapper {
  margin: 0;
  background: #fff;
}

.options-wrapper {
  min-width: 250px;
  padding-bottom: 5px;
  height: auto;
  text-align: center;
  width: 100%;

  overflow: visible;
  padding-top: 2rem;
  background: #585858;
}

.catalogue-location {
  height: 50px;
  background-color: grey;
  color: white;
  text-align: center;
  display: table-cell;
  cursor: pointer;
  vertical-align: middle;
  border-bottom: white 1px solid;
}

.catalogue-location:hover {
  background-color: white;
  color: grey;
}

.catalogue-country:hover {
  background-color: white;
  color: grey;
}

.catalogue-locations-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 290px;
}

.catalogue-wrapper .options-wrapper {
  text-align: center;
  width: 100%;
}

.catalogue-wrapper .options-wrapper > div {
  width: 49% !important;
}

@media (max-width: 992px) {
  // .rendered .pdf-wrapper {
  //   transform: scale(0.9);
  //   transform-origin: top;
  // }

  .rendered .pdf-wrapper canvas {
    width: 100%;
  }
}
