@import '../../../../../../styles/global/variables';
@import '../../../../../../styles/global/mixins';

.weather {
  position: relative;
  padding-top: $global-margin * 2;

  @include breakpoint(medium) {
    padding-top: $global-margin * 3;
  }
}

.weather__item {
  position: relative;
  display: flex;
  align-items: flex-start;

  img {
    flex: 0 0 auto;
    border: $global-border;
    border-radius: $global-radius * 2;
  }

  h3 {
    font-size: 1.125rem;
  }

  ul {
    flex: 1;
  }
}