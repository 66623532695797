.modal {
  border-radius: $global-radius;

  > div:last-child {
    background-color: #00163D !important;
    opacity: 0.6 !important;
  }

  > div > div {
    width: 100% !important;
    max-width: 64rem !important;
    border-radius: $global-radius;
    overflow: hidden;
  }

  > div > div > div {
    border-radius: 0 !important;
    padding: $global-margin * 2 $global-margin;
    overflow: auto;
    max-height: 90vh;    

    @include breakpoint(large) {
      padding: $global-margin * 4 $global-margin;
    }
  }
  
  > div > div > div > * {
    max-width: 55rem;
    margin-inline: auto !important;
    overflow-y: unset !important;
    max-height: unset !important;
    border: none !important;
  }

  h3 {
    margin-block: 0 ($global-margin * 2) !important;
    font-weight: map-get($global-weight, bold) !important;
    line-height: 1.32 !important;    
    color: map-get($grayscale-colors, black) !important;
    font-size: clamp(1.125rem, 2vw, 1.5rem) !important;
    letter-spacing: -0.0125rem !important;    
    padding: 0 !important;
  }

  h3 + div {
    padding: 0 !important;
  }
}

.modal__control {
  padding: 0 !important;
}