@import '../../../../../../styles/global/mixins';


.map {
  position: relative;

  img {
    max-width: none !important;
  }

  button {
    position: absolute;
    bottom: -1.375rem;
    left: calc(50% - 4.6875rem);
    z-index: 2;
  }
}

.map__small {
  height: 10rem;
}

.map__large {
  height: 20rem;
}