.reservation-wrapper {
    border-radius: 5px;
    position: relative;
}

.validation-error input {
    border-color: red !important;
}

.validation-error textarea {
    border-color: red !important;
}

.reservation-wrapper .orange-button,
.users-detail-wrapper .orange-button,
.summary-wrapper .orange-button
{
    float: right;
    margin-left: 5px !important;
}

.reservation-wrapper.disabled {
    display: none;
}

.customer-row {
    display: flex;
    flex-direction: row;
    margin: 0 -5px;
    position: relative;
}

.picker-row label,
.reservation-input label {
    font-weight: 600;
}

.custommers-header .reservation-input {
    margin-top: 0;
    margin-bottom: 0;
}


.reservation-fields {
    display: flex;
    flex-direction: column;
}

.reservation-input {
    display: flex;
    flex-direction: column;
    width: calc(100% - 0px);
    margin: 25px 5px 15px;
    min-width: 180px;
}


.reservation-input.disabled input {
    background-color: #e2e7ef;
}

.input-title {
    padding-bottom: 3px;
}

.custommers-header {
    font-weight: 700;
}

.address-detail {
    margin-top: 25px;
    margin-bottom: 25px;
}

.left-row {
    margin-left: 25px;
}


.private-policy {
    margin-top: 15px
}


select.custom-select {
    height: 40px;
    overflow: hidden;
    padding: 0px 10px;
    line-height: 40px;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
    display: inline-block;
    background-color: rgb(255, 255, 255);
    background-size: 20px;
    background-position: right center;
    background-repeat: no-repeat;
    color: rgb(64, 64, 64);
    font-size: 0.9em;
    font-weight: 500;
    cursor: pointer;
    margin-top: 4px;
    -webkit-appearance: none;
}

.summary-info-wrapper .orange-button {
    margin-left: 5px !important;
}

.reservation-input input, .reservation-input textarea, .reservation-input .datepicker {
    height: 40px;
    margin-top: 3px;
    overflow: hidden;
    padding: 0px 10px;
    line-height: 40px;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
    display: inline-block;
    background-color: rgb(255, 255, 255);
    background-size: 20px;
    background-position: right center;
    background-repeat: no-repeat;
    color: #404040;
    font-size: 0.9em;
    font-weight: 500;
    cursor: default;
}

.reservation-input textarea {
    height: 180px;
    line-height: normal;
    padding: 10px;
}

.reservation-input .datepicker {
    margin-left: 0;
}

.reservation-input .datepicker div {
    width: 100% !important;
    height: 35px !important;

}

.reservation-input .datepicker div input {
    width: 100% !important;
    background-color: red;
    padding: 0px !important;
}

.row {
    display: flex;
    align-items: center;
    margin: 0 -5px;

}

.appliance-type {
    font-weight: 600;
}

.appliance-type .custom-checkbox {
    margin: 0 0 0 20px;
    font-weight: 600;
}

.declaration {
    margin-top: 10px;
    margin-bottom: 20px;
    font-style: italic;
}

.reservation-sent {
    font-weight: 600;
    font-size: 30px;
    text-align: center;
}

.reservation-sent-text {
    padding: 30px 0;
    text-align: center;
}

.reservation-customer-summary-finished {
    display: flex;
}


@media only screen and (max-width: 992px) {
    .row {
        flex-direction: column;
    }

    .appliance-type div {
        margin-top: 15px;
    }

    .appliance-type div .custom-checkbox:first-child {
        margin-left: 0;
    }

    .declaration div {
        margin: 5px 0;
    }
}

@media only screen and (max-width: 500px) {
    .summary-info-wrapper {
        flex-direction: column;
        align-items: start;
    }

}


@media only screen and (max-width: 810px) {
    .responsive-table {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        margin-bottom: 20px;
    }

    .custom-select {
        width: 180px;
    }

}

.validation {
    top: -12px;
    position: absolute;
    color: red;
    font-weight: 600;
    left: 50%;
    transform: translateX(-50%);
}


.pensior-apliance {
    padding: 10px;
    font-size: 13px;
    border-radius: 5px;
    flex-direction: column;
    position: relative;
    top: -10px;
    display: flex;
    background-color: #f6f6f6;
}

.back-button {
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;
    left: -10px;
}

.back-button:hover {
    opacity: 0.8;
}


.sending-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.wrapper-progressBar {
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    border-bottom: solid 1px #e1e1e1;
}

.text-right {
    text-align: right;
}

.progressBar {
    padding: 0;
}

.progressBar li {
    list-style-type: none;
    float: left;
    width: 33%;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .progressBar li {
        font-size: 13px;
    }
}

.progressBar li:before {
    content: "2";
    line-height: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: white;
    color: rgb(56, 102, 150)
}

.progressBar li.active:before {
    color: #fff;
}

.progressBar li:nth-child(1):before {
    content: "1";
}

.progressBar li:nth-child(2):before {
    content: "2";
}

.progressBar li:nth-child(3):before {
    content: "3";
}


.progressBar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressBar li:first-child:after {
    content: none;
}

.progressBar li.active {
    color: rgb(56, 102, 150);
}

.progressBar li.active:before {
    border-color: rgb(56, 102, 150);
    background-color: rgb(56, 102, 150)
}

.progressBar .active:after {
    background-color: rgb(56, 102, 150);
}
