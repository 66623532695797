@import '../../../../styles/global/variables';
@import '../../../../styles/global/mixins';

.pagination {
	position: relative;
	list-style: none;
	margin: 0;
	padding-left: 0;
	display: flex;
	flex-direction: row;
	font-size: 0.875rem;

	.pagination__item {
		padding: 0.375rem 0.75rem ;
		border: 0.0625rem solid map-get($grayscale-colors, gray-100);
		border-radius: 0.375rem;
		margin-right: 0.5rem;
		font-weight: map-get($global-weight, semi);
		cursor: pointer;
		transition: $global-transition;

		&:hover {
			color: map-get($brand-colors, primary);
			border-color: map-get($brand-colors, primary);
		}

		&.is-active {
			color: map-get($grayscale-colors, white);
			background-color: map-get($brand-colors, primary);
		}
	}
}
