$cells: 12;
$gap: 0.75rem;

.cell {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
  max-width: 100%;
}

.cell.cell--auto,
.gap .cell.cell--auto {
  flex: 1 1 0;
  width: auto;    
}

.cell.cell--shrink,
.gap .cell.cell--shrink {
  width: auto;    
} 

.gap .cell.cell--shrink {
  margin-inline: $gap;
}

.cell--bottom {
  align-self: flex-end;
}

@for $i from 1 through $cells {
  .cell--#{$i} {
    width: calc((100 / (#{$cells} / #{$i}) ) * 1%);
  }

  .gap .cell--#{$i} {
    width: calc(((100 / (#{$cells} / #{$i}) ) * 1%) - (#{$gap} * 2));
    margin-inline: $gap;
  }
}

@include breakpoint(medium) {
  @for $i from 1 through $cells {
    .cell--#{$i}\:md {
      width: calc((100 / (#{$cells} / #{$i}) ) * 1%);
    }
  }

  @for $i from 1 through $cells {
    .gap .cell--#{$i}\:md {
      width: calc(((100 / (#{$cells} / #{$i}) ) * 1%) - (#{$gap} * 2));
      margin-inline: $gap;
    }
  }

  .cell--auto\:md,
  .gap .cell--auto\:md {
    flex: 1 1 0;
    width: auto; 
  }  

  .cell--shrink\:md,
  .gap .cell--shrink\:md {
    width: auto; 
  }

  .gap .cell--auto\:md,
  .gap .cell--shrink\:md {
    margin-inline: $gap;
  }
}

@include breakpoint(large) {
  @for $i from 1 through $cells {
    .cell--#{$i}\:lg {
      width: calc((100 / (#{$cells} / #{$i}) ) * 1%);
    }
  }

  @for $i from 1 through $cells {
    .gap .cell--#{$i}\:lg {
      width: calc(((100 / (#{$cells} / #{$i}) ) * 1%) - (#{$gap} * 2));
      margin-inline: $gap;
    }
  }

  .cell--auto\:lg,
  .gap .cell--auto\:lg {
    flex: 1 1 0;
    width: auto; 
  }  

  .cell--shrink\:lg,
  .gap .cell--shrink\:lg {
    width: auto; 
  }  

  .gap .cell--auto\:lg,
  .gap .cell--shrink\:lg {
    margin-inline: $gap;
  }  
}