@import '../../styles/global/variables';
@import '../../styles/global/mixins';

.usp{
  position: relative;

  h2{
    font-size: clamp(1.75rem,2vw, 2rem);
  }

  h3{
    font-size: clamp(1.375rem,2vw, 1.5rem);
  }

}

