@import '../../../../../styles/global/variables';
@import '../../../../../styles/global/mixins';

.prices {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 27rem;
  overflow: auto;

  > li {
    position: relative;
    padding: $global-margin;
    background-color: map-get($grayscale-colors, white);
    border: 0.0625rem solid map-get($grayscale-colors, gray-100);  
    border-radius: $global-radius / 2;  
    cursor: pointer;
    transition: $global-transition;

    &:hover {
      background-color: map-get($brand-colors, primary-100);
      border-color: map-get($brand-colors, primary);
    }

    > div:first-child {
      margin-bottom: 0.5rem;
    }    
  }

  > li + li {
    margin-top: $global-margin / 2;
  }

  ul > li {
    line-height: 1.8;
  }
}

.label {
  position: relative;
  display: inline-block;
  font-size: 0.625rem;
  line-height: 1.25rem;
  font-weight: map-get($global-weight, bold);
  text-transform: uppercase;
  color: map-get($grayscale-colors, white);
  background-color: map-get($brand-colors, primary-400);
  padding: 0.125rem 0.5rem;
  letter-spacing: 0.032rem;
  margin-bottom: 0.25rem;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid map-get($brand-colors, primary);
    transform: rotate(-180deg);
  }
}