@import '../../../../styles/global/variables';
@import '../../../../styles/global/mixins';

.stepper {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    position: relative;
    line-height: 1;
    padding: 0 1rem;
		list-style: none;

    @include breakpoint(medium) {
      padding: 0 2rem;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }    

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 0.0625rem);
      left: 0;
      width: 1rem;
      border-top: 0.125rem solid map-get($grayscale-colors, gray-900);

      @include breakpoint(medium) {
        width: 2rem;
      }
    }

    &::after {
      left: auto;
      right: 0;
    }

    &:first-child::before {
      content: none;
    }

    &:last-child::after {
      content: none;
    }
  }
}

.stepper__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-weight: map-get($global-weight, bold);
  font-size: 0.875rem;
  color: map-get($grayscale-colors, gray-900);
  background-size: 1.125rem;
  border: 0.125rem solid map-get($grayscale-colors, gray-900);
  background-repeat: no-repeat;
  background-position: center center;

  @include breakpoint(large up) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.stepper li.is-done {
  &::before,
  &::after {
    border-color: map-get($brand-colors, primary-400);
  }

  .stepper__link {
    font-size: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
    color: map-get($grayscale-colors,white);
    background-color: map-get($brand-colors, primary-400);
    border-color: map-get($brand-colors, primary-400);
  }

  + li::before {
    border-color: map-get($brand-colors, primary-400);
  }
}

.stepper li.is-active {
  .stepper__link {
    color: map-get($brand-colors, primary-400);
    border-color: map-get($brand-colors, primary-400);
  }
}
