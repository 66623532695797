@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

.inside-pdf-wrapper {
  margin-left: 0;
  margin: 0 auto;
  padding: 2rem 0;
  max-width: 895px;
  min-height: 500px;
  text-align: center;
  clear: both;
  h1 {
    margin: 40px 0 30px 0;
  }
  .pdf-controls {
    i {
      padding: 5px 8px;
      background: rgb(237, 183, 0);
      border-radius: 5px;
      margin: 3px;
      color: #fff;
    }
  }
}
body {
  .catalogue-wrapper {
    .options-wrapper {
      > div {
        width: 300px !important;
        float: none !important;
        display: inline-block;
        margin: 3px !important;
      }
    }
  }
}
.listing {
  text-align: center;
  position: relative;
  font-size: 0.875rem;
  color: map-get($grayscale-colors, black);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .material-icons {
    color: map-get($brand-colors, primary-medium);
    &:hover {
      color: map-get($brand-colors, primary);
    }
  }
  //i {
  //  margin: 0 20px;
  //  position: relative;
  //  top: 7px;
  //  background: #fff;
  //  border: 1px solid transparent;
  //  padding: 0;
  //  padding-right: 6px;
  //  padding-bottom: 6px;
  //  color: rgb(56, 102, 150);
  //  font-weight: 400;
  //  font-size: 30px;
  //  text-align: left;
  //  width: 25px;
  //  height: 25px;
  //  &:hover {
  //    width: 25px;
  //    height: 25px;
  //    border-color: transparent;
  //  }
  //}
  //div {
  //  display: inline-block;
  //}
}
.catalogue-loader {
  text-align: center;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  margin: 30px auto;
  position: fixed;
}
.navigate {
  cursor: pointer;
}
.navigate.disabled {
  color: map-get($grayscale-colors, gray-800);
  cursor: default;
  &:hover {
    color: map-get($grayscale-colors, gray-800);
    cursor: default;
  }
}
.navigate:first-of-type {
  margin-right: 1rem;
}

.navigate:last-of-type {
  margin-left: 1rem;
}

// @media (min-width: 1024px) {
//    .listing {
//      position: fixed;
//      z-index: 8;
//      width: 100%;
//      top: 138px;
//    }
//   .inside-pdf-wrapper {
//     padding-top: 150px;
//   }
// }
@media (max-width: 400px) {
  .catalogue-loader {
    top: 200px;
  }
}
