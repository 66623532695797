p {
  margin-block: 0 $global-margin;
  letter-spacing: 0.032rem;
}

strong {
  font-weight: map-get($global-weight, bold);
}

.lead {
  font-size: clamp(1rem, 2vw, 1.125rem);
  letter-spacing: 0.032rem;
}

.text--14 {
  font-size: 0.875rem;
}

.text--16 {
  font-size: 1rem;
}

.text--18 {
  font-size: 1.125rem;
}

.text--normal {
  font-weight: map-get($global-weight, regular);
}

.text--semi {
  font-weight: map-get($global-weight, semi);
}

.text--bold {
  font-weight: map-get($global-weight, bold);
}

.text--price {
  letter-spacing: 0.0125rem;
  line-height: 1;
}

.text--inline {
  display: flex;
  align-items: center;
}