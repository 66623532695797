.section {
  position: relative;
}

.section--medium {
  padding-block: $global-margin * 3;

  @include breakpoint(medium) {
    padding-block: $global-margin * 4;
  }
}

.section--global {
  padding-block: $global-margin * 3;

  @include breakpoint(medium) {
    padding-block: $global-margin * 5;
  }

  @include breakpoint(xlarge) {
    padding-block: $global-margin * 7;
  }  
}

.section--banner::before {
  content: '';
  position: absolute;
  top: calc(50% - 0.0625rem);
  left: 0;
  width: 100%;
  height: 0.0625rem;
  background-color: map-get($grayscale-colors, gray-100);
}