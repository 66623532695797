@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

.accordion {
	position: relative;
	list-style-type: none;
  margin: 0;
  padding: 0;
  color: map-get($brand-colors, gray-900)
}

.accordion__item {
	border-bottom: 0.0625rem solid map-get($grayscale-colors, gray-100);
  cursor: pointer;
}

.accordion__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.6;
	color: map-get($grayscale-colors, gray-900);
  font-weight: map-get($global-weight, bold);
  padding-block: 1.5rem;

  &:hover,
  &:active,
  &:focus,
  &.is-active {
    color: map-get($brand-colors, primary);
  }
}

.icon {
	position: relative;
	flex: 0 0 auto;
	display: inline-block;
  width: 1.25rem;
  height: 1.25rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    transition: $global-transition;
    margin: auto;
    background-color: map-get($grayscale-colors, gray-900);
  }

  &::before {
    width: 1rem;
    height: 0.125rem;
  }

  &::after {
    width: 0.125rem;
    height: 1rem;
  }
}

.accordion__title.is-active .icon::after {
  height: 0;
}