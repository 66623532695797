.nav {
  position: absolute;
  top: calc(100% - 0.3125rem);
  left: 0;
  width: 100%;
  background-color: map-get($grayscale-colors, white);

  z-index: 10;
  max-height: 0;
  overflow: auto;
  transition: $global-transition;

  @include breakpoint(large) {
    position: relative;
    top: 0;
    max-height: unset;
    overflow: visible;
  }

  &.is--active {
    max-height: 24rem;
    border-top: 0.0625rem solid map-get($grayscale-colors, gray-100);

    @include breakpoint(large) {
      border-top: none;
    }
  }
}
