.link {
  position: relative;
  top: 0;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  transition: $global-transition;
  cursor: pointer;
}

.link--large {
  font-weight: map-get($global-weight, bold);
  font-size: clamp(1rem, 2vw, 1.125rem);
  letter-spacing: 0.045rem;
}

.link--underline {
  text-decoration: underline;
}

.link--short {
  width: fit-content;
}

.link--file {
  padding: 0.875rem 1rem ;
  font-weight: map-get($global-weight, semi);
  line-height: 1.4;
  border: $global-border;
  border-radius: $global-radius / 2;

  &:hover:not(.link--disabled) {
    border-color: map-get($brand-colors, primary);
  }
}

.link--disabled {
  cursor: default;
}

.link--back {
  top: 0.125rem;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  border: 0.125rem solid map-get($grayscale-colors, white);
  border-radius: 50%;
  flex: 0 0 auto;
}

// .link--customer-section {
//   position: absolute;
//   top: 6rem;
//   left: 1rem;
//   width: fit-content;

//   @include breakpoint (large){
//     top: 6.5rem;
//   }

//   a {
//     padding: 0.5rem 1rem;
//     border-radius: 0 0 $global-radius/2 $global-radius/2;
//   }

//   a + a {
//     margin-left: 0.25rem;
//   }
// }
