html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}

*, *::before, *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: $global-font;
  font-size: 1rem;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
}

main {
  overflow: clip;
  flex: 1;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}