.color--white {
  color: map-get($grayscale-colors, white);
}

.color--primary {
  color: map-get($brand-colors, primary);
}

.color--secondary {
  color: map-get($brand-colors, secondary);
}

.color--primary-200 {
  color: map-get($brand-colors, primary-200);
}

.color--primary-highlight {
  color: map-get($brand-colors, primary-highlight);
}

.color--gray-900 {
  color: map-get($grayscale-colors, gray-900);
}

.color--primary-highdark {
  color: map-get($brand-colors, primary-highdark);
}

.color--black {
  color: map-get($grayscale-colors, black);
}

.color--danger {
  color: $red;
}