@import '../../../../styles/global/variables';
@import '../../../../styles/global/mixins';

.intro {
  position: relative;
  padding-block: $global-margin * 4;
  background-color: map-get($brand-colors, secondary-light);

  @include breakpoint(medium) {
    padding-block: $global-margin * 4 $global-margin * 6.5;
  }
}

.intro__label {
  position: relative;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  margin-top: 0.5rem;
  background-color: map-get($brand-colors, primary-400);
  font-weight: map-get($global-weight, bold);
  letter-spacing: 0.032rem;
  color: map-get($grayscale-colors, white);
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    top: 1.875rem;
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid map-get($brand-colors, primary);
  }
}

.content {
  padding-bottom: $global-margin * 3;

  @include breakpoint(medium) {
    padding-bottom: $global-margin * 5;
  }

  @include breakpoint(xlarge) {
    padding-bottom: $global-margin * 7;
  }
}

.aside {
  margin-top: $global-margin * 2;
  max-width: 20rem;
  margin: $global-margin * 2 auto 0;

  @include breakpoint(large) {
    margin-top: $global-margin * 4;
    margin-inline: 1.5rem 0;
  }

  @include breakpoint(xlarge) {
    margin-inline: 3rem 0;
  }
}
