@import '../../../../../styles/global/variables.scss';
@import '../../../../../styles/global/mixins.scss';

.sort {
	width: 6.5rem;
	display: flex;
	justify-content: space-between;
	margin-right: 0.5rem;
}

.sort__item {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: map-get($brand-colors, primary-highdark);
	border: 0.0625rem solid map-get($grayscale-colors, gray-100);
	border-radius: 0.375rem;
	height: 100%;
	padding-inline: 0.6875rem;

	:active,
	:hover,
	:focus {
		color: map-get($brand-colors, primary);
	}
}

.is-active {
	color: map-get($brand-colors, primary);
}