$loops: 4;

@for $i from 0 through $loops {
  .margin--top-#{$i} {
    margin-top: calc(#{$global-margin} * #{$i});
  }

  .margin--bottom-#{$i} {
    margin-bottom: calc(#{$global-margin} * #{$i});
  }

  .margin--block-#{$i} {
    margin-block: calc(#{$global-margin} * #{$i});
  }

  .margin--right-#{$i} {
    margin-right: calc(#{$global-margin} * #{$i});
  }

  .margin--left-#{$i} {
    margin-left: calc(#{$global-margin} * #{$i});
  }  
}

@include breakpoint(medium) {
  @for $i from 0 through $loops {
    .margin--top-#{$i}\:md {
      margin-top: calc(#{$global-margin} * #{$i});
    }

    .margin--bottom-#{$i}\:md {
      margin-bottom: calc(#{$global-margin} * #{$i});
    }

    .margin--block-#{$i}\:md {
      margin-block: calc(#{$global-margin} * #{$i});
    }

    .margin--right-#{$i}\:md {
      margin-right: calc(#{$global-margin} * #{$i});
    }

    .margin--left-#{$i}\:md {
      margin-left: calc(#{$global-margin} * #{$i});
    }
  }
}

@include breakpoint(large) {
  @for $i from 0 through $loops {
    .margin--top-#{$i}\:lg {
      margin-top: calc(#{$global-margin} * #{$i});
    }

    .margin--bottom-#{$i}\:lg {
      margin-bottom: calc(#{$global-margin} * #{$i});
    }

    .margin--block-#{$i}\:lg {
      margin-block: calc(#{$global-margin} * #{$i});
    }

    .margin--right-#{$i}\:lg {
      margin-right: calc(#{$global-margin} * #{$i});
    }

    .margin--left-#{$i}\:lg {
      margin-left: calc(#{$global-margin} * #{$i});
    }    
  }
}

.margin--top-24 {
  margin-top: $global-margin * 1.5;
}

.margin--bottom-24 {
  margin-bottom: $global-margin * 1.5;
}

.margin--right-24 {
  margin-right: $global-margin * 1.5;
}

.margin--top-auto {
  margin-top: auto;
}