@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

$compareWidth: 16.25rem;
$fullWidth: 18.25rem;

.compare {
  position: fixed;
  top: 10rem;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  z-index: 20;
  background-color: map-get($grayscale-colors, white);
  transition: $global-transition;
  height: 12.5rem;
  box-shadow: $global-shadow;
}

.compare__hidden {
  right: -$fullWidth;
}

.compare__closed {
  right: -$compareWidth;
}

.compare__shown {
  right: -$compareWidth;
}

.compare__header {
  position: relative;
  background-color: map-get($brand-colors, primary);
  width: 2rem;
  cursor: pointer;

  h2 {
    position: absolute;
    transform: rotate(-90deg);
    top: 6rem;
    left: -3.375rem;
    font-size: 1rem;
    white-space: nowrap;
    margin-bottom: 0;
  }
}

.compare__body {
  width: $compareWidth;
  display: flex;
  flex-direction: column;
  padding: $global-margin;
}