@import '../../../../../styles/global/variables';
@import '../../../../../styles/global/mixins';

$galleryGap: 0.5rem;

.gallery {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: $global-margin * 2;

  @include breakpoint(large) {
    margin-top: 0;
  }
}

.gallery__side,
.gallery__main {
  max-height: 23.75rem;

  > a {
    display: block;
    height: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.gallery__side {
  display: flex;
  flex-direction: row;
  gap: $galleryGap;
  margin-bottom: $galleryGap;

  @include breakpoint(medium) {
    flex-direction: column;
    flex: 0 0 12.5rem;
    margin-right: $galleryGap;
    margin-bottom: 0;
  }

  img {
    aspect-ratio: 4/3;
  }
}

.gallery__main {
  flex: 100%;

  @include breakpoint(medium) {
    flex: 1;
  }
  
   img {
    aspect-ratio: 16/9;
  }  
}

.gallery__thumbs {
  flex: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: $galleryGap;
  margin-top: $galleryGap;

  a {
    flex: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 5.625rem;
    max-height: 5.625rem;
  }
}