@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

@mixin inputStyles {
	position: relative;
	border: $inputBorder;
	border-radius: $inputRadius;	
	appearance: none;	
	margin: 0;
	font-size: 0.875rem;
	font-weight: map-get($global-weight, semi);	
	cursor: pointer;
	outline: none;
	transition: $global-transition;
	background-color: map-get($grayscale-colors, white);
	font-family: $global-font;

	&:hover,
	&:focus {
		border-color: map-get($brand-colors, primary);
	}	
}

@mixin checkboxStyles {
	width: 1.5rem;
	height: 1.5rem;
	flex: 0 0 auto;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-color: map-get($grayscale-colors, white);
	background-size: 1rem;
	margin-right: 0.875rem;	
}

$inputHeight: 3rem;
$inputBorder: 0.0625rem solid map-get($grayscale-colors, gray-100);
$inputRadius: 0.375rem;

.form__group {
	position: relative;
	display: flex;
	flex-direction: column;
}

.form__group--inline {
	flex-direction: row;
}

.input,
.select,
.checkbox,
.textarea,
.choice__input {
	@include inputStyles();

	&[disabled] {
		cursor: not-allowed;
		background-color: rgba(226,231,239, 0.4);
	}
}

.input,
.select,
.choice__input {
	height: $inputHeight;
	padding-inline: 1rem;
}

.select {
	padding-right: 3rem;
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.708' height='16'%3E%3Cg data-name='Icon'%3E%3Cpath data-name='965c06cd-0d24-47b9-86f4-50807f24a755' d='m1.414 3.97 7.94 7.94 7.94-7.94' fill='none' stroke='%230049c6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
}

.checkbox {
	@include checkboxStyles();

	&:checked {
		background-color: map-get($brand-colors, primary);
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
	}

	+ label {
		margin-top: 0.125rem;
		cursor: pointer;
	}
}

.label {
	position: relative;
	margin-bottom: 0.5rem;
  font-size: 0.875rem;
	line-height: 1.5;
	color: map-get($brand-colors, primary-highdark);
	font-weight: map-get($global-weight, semi);
	cursor: pointer;
}

.datepicker {
  position: relative;
  display: flex;
  align-items: center;
  border: $inputBorder;
  border-radius: $inputRadius;
}

.datepicker__field {
  position: relative;
  flex: 1;
  font-weight: map-get($global-weight, semi);
  font-size: 0.875rem;

  &:first-child {
    border-right: $inputBorder;
  }
}

.datepicker__input {
  position: relative;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22'%3E%3Cg data-name='Icon' transform='translate(1 1)' fill='none' stroke='%230049c6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Crect width='18' height='18' rx='2' transform='translate(0 2)'/%3E%3Cpath data-name='8d797a4c-f767-4a7f-9911-c0ac887bb7c9' d='M13 0v4'/%3E%3Cpath data-name='2169adc6-3603-41b4-9c25-ca817bae453e' d='M5 0v4'/%3E%3Cpath data-name='03e7f8e4-bcde-4e45-8016-dd093d5736d7' d='M0 8h18'/%3E%3C/g%3E%3C/svg%3E");

  hr {
    display: none;
  }

  input {
    padding: 0.5rem 1rem !important;
    box-sizing: border-box !important;
    appearance: none !important;
  }

	> div:first-child {
		height: calc(#{$inputHeight} - 0.125rem) !important;
	}

  div > div {
    left: 1rem;
		bottom: 0.625rem !important;
    color: map-get($grayscale-colors, black) !important;
  }
}

.datepicker__reset {
  position: absolute;
  top: calc(50% - 0.5rem);
  right: $inputHeight;
  color: map-get($brand-colors, secondary);
  cursor: pointer;
}

.choice {
	position: relative;
}

.choice__input {
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	line-height: $inputHeight;
	padding-right: $inputHeight;
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.708' height='16'%3E%3Cg data-name='Icon'%3E%3Cpath data-name='965c06cd-0d24-47b9-86f4-50807f24a755' d='m1.414 3.97 7.94 7.94 7.94-7.94' fill='none' stroke='%230049c6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");	
}

.choice__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: map-get($grayscale-colors, white);
  z-index: 8;
  box-shadow: $global-shadow;
  border-radius: $inputRadius;
  overflow: auto;
  max-height: 20rem;

	ul > li {
		display: flex;
		align-items: center;
    font-weight: map-get($global-weight, semi);
    font-size: 0.875rem;
    padding: 0.875rem 1rem;
    cursor: pointer;
    transition: $global-transition;

    &:hover {
      color: map-get($grayscale-colors, white);
      background-color: map-get($brand-colors, primary);
    }
	}	

	ul > li + li {
		border-top: $inputBorder;	
	}
}

.choice--disabled {
	pointer-events: none;

	.choice__input {
		background-color: map-get($grayscale-colors, gray-100);
	}
}