@import '../../styles/global/variables';
@import '../../styles/global/mixins';

.footer {
  position: relative;
  background-color: map-get($brand-colors, primary-dark);
  padding-bottom: $global-margin * 2;
  margin-top: $global-margin * 1.5;
  z-index: 2;

  @include breakpoint(medium) {
    padding-bottom: $global-margin * 4;
  }
}

.footer__section {
  position: relative;

  &:first-child {
    top: -$global-margin * 1.5;
    padding-bottom: $global-margin;
    margin-bottom: $global-margin * 2;
    border-bottom: 0.0625rem solid map-get($brand-colors, primary-medium);

    @include breakpoint(medium) {
      padding-bottom: $global-margin * 2;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}

.footer__contact {
  position: relative;
  display: flex;
  align-items: center;
  padding: $global-margin * 1.5 $global-margin;
  background-color: map-get($brand-colors, primary-dark);
  border-radius: $global-radius;

  @include breakpoint(medium) {
    padding: $global-margin * 2;
    margin-inline: $global-margin * 1.5;
  }

  @include breakpoint(large) {
    justify-content: center;
    width: 23.75rem;
  }
}