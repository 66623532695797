@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

.block {
	position: relative;
	margin-top: -3rem;
  z-index: 8;

	@include breakpoint(large) {
    margin-top: -5rem;
	}
}

.card {
	position: relative;
	display: flex;
	background-color: map-get($grayscale-colors, white);
	border-top: 0.125rem solid map-get($brand-colors, primary);
	box-shadow: 0 0.1875rem 0.375rem map-get($grayscale-colors, gray-100);
	padding: 1.5rem 1.625rem;
	height: 100%;
}

.card__image {
	position: relative;
	flex: 0 0 auto;
	margin-right: 1rem;

	img {
		border-radius: 50%;
		height: 4rem;
		overflow: hidden;
		object-fit: cover;
	}
}

.card__content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	h2 {
		font-size: clamp(0.875rem, 1.8vw, 1rem);
		margin: 0;
	}
}
.card__phone {
	display: flex;
}