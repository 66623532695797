@import '../../../../styles/global/variables';
@import '../../../../styles/global/mixins';

.filter {
  position: relative;
  top: $global-margin;
  padding: $global-margin * 3 $global-margin;
  background-color: map-get($grayscale-colors, white);
  border-radius: 1rem;
  box-shadow: $global-shadow;

  @include breakpoint(medium) {
    top: $global-margin * 2;
    padding: $global-margin * 3 $global-margin * 2;
  }

  @include breakpoint(xlarge) {
    padding: $global-margin * 4 $global-margin * 3.5;
  }  
}

.filter__label {
  display: block;
  font-size: 0.875rem;
  font-weight: map-get($global-weight, semi);
  color: map-get($grayscale-colors, black);
}