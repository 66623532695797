@import '../../../../styles/global/variables';
@import '../../../../styles/global/mixins';

.slider {
  width: 100%;
  height: 1.5rem;
  margin-bottom: 0.5rem;

  div[role="slider"] {
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    background: map-get($brand-colors, primary-semi);
    cursor: pointer;

    &::before {
      content: 'od';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: map-get($global-weight, semi);
      font-size: 0.75rem;
      letter-spacing: 0.0385rem;
      color: map-get($grayscale-colors, white);
    }

    &:last-child::before {
      content: 'do';
    }
  }
}

.slider__bar {
  position: relative;
  top: 0.625rem;
  height: 0.25rem;
  background: map-get($brand-colors, primary);
  border-radius: 1rem;
}