.align--left {
  text-align: left;
}

.align--center {
  text-align: center;
}

.align--right {
  text-align: right;
}

.align--left\:md {
  @include breakpoint(medium) {
    text-align: left;
  }
}

.align--right\:md {
  @include breakpoint(medium) {
    text-align: right;
  }
}
