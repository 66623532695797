@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

.menu {
  position: relative;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;

  @include breakpoint(large) {
    align-items: center;
    flex-direction: row;
  }
}

.menu li {
  position: relative;
}

.menu__link {
  display: flex;
  align-items: center;
  padding: $global-margin;
  font-weight: map-get($global-weight, bold);
  font-size: 0.875rem;
  color: map-get($brand-colors, primary-highdark);
  transition: $global-transition;
  text-decoration: none;
  letter-spacing: -0.0125rem;
  cursor: pointer;

  &:hover {
    color: map-get($brand-colors, primary);
  }
}

.dropdown {
  position: relative;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: map-get($grayscale-colors, white);
  z-index: 8;
  padding: 0;
  list-style: none;
  border-radius: $global-radius;
  

  @include breakpoint(large) {
    position: absolute;
    min-width: 16rem;
    box-shadow: 0 3rem 5rem #00163D29;  
    padding: $global-margin 0;
  }

  li + li {
    border-top: 0.0625rem solid map-get($grayscale-colors, gray-100);
  }
}