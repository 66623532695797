.title {
  position: relative;
}

h1, h2, h3 {
  margin-block: 0 ($global-margin * 2);
  font-weight: map-get($global-weight, bold);
  line-height: 1.32;
  color: map-get($grayscale-colors, black);
}

h1, .h1 {
  font-size: clamp(2rem, 2vw, 2.375rem);
  letter-spacing: -0.0125rem;
}

h2, .h2 {
  font-size: clamp(1.5rem, 2vw, 2rem);
  letter-spacing: -0.025rem;
}

h3, .h3 {
  font-size: clamp(1.125rem, 2vw, 1.5rem);
  letter-spacing: -0.0125rem;
}

h4, .h4 {
  font-size: clamp(1rem, 2vw, 1.125rem);
  letter-spacing: -0.0025rem;
}