hr {
  margin-top: 0.5rem;
  border-top: 0;
  border-right: 0;
  border-bottom: 0.0625rem solid map-get($grayscale-colors, gray-200);
  border-left: 0;
  opacity: 1;
}

img {
  max-width: 100%;
  height: auto;
}