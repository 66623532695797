.list {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    position: relative;
    padding: 0;
  }
}

.list--breadcrumbs {
  display: flex;
  margin-bottom: $global-margin * 1.5;

  li {
    + li {
      margin-left: $global-margin * 2;

      &::before {
        content: '';
        position: absolute;
        top: 0.625rem;
        left: -1.125rem;
        width: 0.375rem;
        height: 0.375rem;
        background-color: map-get($brand-colors, primary);
        border-radius: 50%;
      }
    }
  }
}

.list--rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .list__item {
    display: flex;
    flex-flow: column nowrap;
    padding-block: 1rem;
  }

  .list__container {
    position: relative;
  }

  .list__stars {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    gap: 0.25rem;
  }

  .list__full {
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    display: flex;
    margin-top: 0.5rem;
    gap: 0.25rem;
  }
}

.list--info {
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block: $global-margin;

    @include breakpoint(medium) {
      align-items: center;
    }
  }

  li + li {
    border-top: $global-border;
  }
}

.list--compare {
  max-height: 8.25rem;
  overflow: auto;

  li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
}

.list--inline {
  display: flex;
  align-items: center;
}
