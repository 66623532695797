@import '../../../../styles/global/variables';
@import '../../../../styles/global/mixins';

.hero {
  position: relative;
  margin-bottom: $global-margin;

  @include breakpoint(medium) {
    margin-bottom: $global-margin * 2;
  }  
}

.hero__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hero__content {
  position: relative;
  z-index: 2;
  padding-top: $global-margin * 3;
  background: $global-gradient;
}

.hero__article {
  padding-bottom: $global-margin * 2;

  @include breakpoint(large) {
    padding-top: $global-margin;
    padding-bottom: $global-margin * 5;
  }
}