@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

.crossroad {
  position: relative;
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    flex-direction: row;
  }
}

.crossroad__content {
  position: relative;
  z-index: 2;

  svg {
    width: auto;
    height: 3rem;
    display: none;

    @include breakpoint (medium) {
      display: block;
    }

    @include breakpoint(large) {
      height: 5rem;
    }
  }

  p {
    line-height: 1.8;
    margin: 0 0 $global-margin*2;
    opacity: 1;
    transition: $global-transition;
  }

  //div {
  //  @include breakpoint(large) {
  //    position: absolute;
  //    left: 0;
  //    opacity: 0;
  //    bottom: -3rem;
  //  }
  //}
}

.crossroad__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.crossroad__link {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: calc(100vh / 3);
  padding: $global-margin;
  cursor: pointer;

  @include breakpoint(large) {
    flex: 1;
    height: 100vh;
    padding: $global-margin * 1.5;
  }

  @include breakpoint(xlarge) {
    padding: $global-margin * 6 $global-margin * 4;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $global-gradient;
    z-index: 2;
  }

  //&:hover {
  //  .crossroad__content p {
  //    opacity: 0;
  //  }
//
  //  .crossroad__content div {
  //    opacity: 1;
  //    bottom: 0;
  //  }
  //}
}

.logo {
	position: absolute;
	display: none;
	top: 2.625rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

	@include breakpoint(large){
    display: block;
	}
}
