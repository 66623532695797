@import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
@import "constants";
@import "buttons";

// input,
// select,
// button,
// textarea {
//   font-family: 'Open Sans', sans-serif;
// }

.orange-button.disabled {
  color: white !important;
  background-color: grey !important;
  border-color: grey !important;
  cursor: default !important;
}

.orange-button.disabled:hover {
  color: white !important;
  background-color: grey !important;
  border-color: grey !important;
  cursor: default !important;
}

img {
  -webkit-print-color-adjust: exact;
}

table tbody th {
  font-weight: 300;
}

.generating-pdf {
  -webkit-print-color-adjust: exact;
}

#tickets ul {
  padding: 0;
}

.mainmenu a:hover {
  border-bottom-color: #000;
}

.mainmenu-desktop li:last-child {
  border-right: none !important;
}

.mainmenu-responsive input::-webkit-input-placeholder {
  color: #e1e1e1;
}

.mainmenu-responsive input:-moz-placeholder {
  color: #e1e1e1;
}

.mainmenu-responsive input::-moz-placeholder {
  color: #e1e1e1;
}

.mainmenu-responsive input:-ms-input-placeholder {
  color: #e1e1e1;
}

.scrollTop {
  z-index: 9 !important;
}

.video-wrapper h1,
.tours-main-title,
.tours-slider-value,
.tour-header,
.tour-tile,
.hp-grid h2,
.section-image h1 {
  font-family: 'Open Sans', sans-serif;
}

.video-wrapper {
  background-image: url("https://cdtravel-2.s3.eu-central-1.amazonaws.com/background.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.blue-select {
  -webkit-appearance: none;
}

.hp-lastminute-table td {
  width: calc(25% - 16px);
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.react-parallax-content {
  background: rgba(0, 0, 0, 0.3);
}

.pricelist-div-line {
  overflow-x: auto;
}

.tour-info-wrapper {
  margin-top: -24px;
}

.tour-price-list table tr th,
.pricelist-div-line table tr th,
.occupation table tbody tr th {
  padding: 9px 6px;
  text-align: left;
  cursor: default;
}

.tour-price-list table tr,
.pricelist-div-line table tr,
.occupation table tbody tr {
  border-bottom: 1px solid rgba(56, 102, 150, 0.2);
}

.tour-price-list table tr:first-child th,
.pricelist-div-line table tr:first-child th,
.occupation table tr:first-child th {
  text-align: left;
  font-weight: 600;
  max-width: 140px;
}

.pricelist-div-line table tr th {
  text-align: left;
  cursor: pointer;
}

.pricelist-div-line table tr:hover {
  background-color: #f2f2f2;
}

.pricelist-div-line table tr th:first-child {
  font-weight: 600;
}

.pricelist-div-line table tr th:last-child {
  text-align: right;
}

.tour-price-list table tr th:first-child {
  width: 130px;
  text-align: left;
}

.tour-price-list table tr:first-child,
.occupation table tr:first-child {
  text-align: right;
}

/*.tour-price-list table tr:hover {
    color: #edb700
}*/

body .right-float {
  float: right !important;
}

body .normal .react-tabs [role='tab'][aria-selected='true'] div {
  background-color: transparent !important;
  color: #484848;
  border-bottom: 4px solid #edb700 !important;
}

body .employee .react-tabs [role='tab'][aria-selected='true'] div {
  border-bottom: 4px solid rgb(175, 200, 54) !important;
}

body .react-tabs [role='tab'][aria-selected='true'] {
  background-color: transparent !important;
}

#content .scrolled {
  position: relative !important;
}

#content > div,
#content .tour-header,
#content .tour-header-box h1,
#content .tour-header-box h2,
#content .tour-header-box h2 img {
  transition: 0.2s;
}

#content .scrolled > div {
  height: 120px !important;
}

#content .scrolled .tour-header-box h1 {
  font-size: 1.2em !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

#content .scrolled .tour-header-box h2 {
  font-size: 0.9em !important;
  margin-top: -3px !important;
}

#content .scrolled .tour-header-box h2.sticker {
  /*transform: none !important;*/
  top: 22px !important;
  font-size: 0.8em !important;
}

#content .scrolled .tour-header-box h2 img {
  width: 0px !important;
  height: 0px !important;
  margin-bottom: 27px !important;
  margin-right: 0px !important;
  margin-left: -2px !important;
  top: 7px !important;
}

#content .tour-scroll-head {
  transform: none !important;
}

.react-tabs [role='tablist'] {
  padding-bottom: 0 !important;
  border-bottom: transparent;
  overflow: auto;
  top: -10px !important;
  margin: 0 0 20px 0;
}

/*.pricelist-div-line tr:nth-child(2n),
.occupation tr:nth-child(2n) {
    background-color: #f5f5f5;
}*/

.datepicker hr {
  display: none;
}

.cms-page strong {
  color: #287fb8;
}

.gradient {
  background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  color-stop(0%, rgba(0, 0, 0, 0.15)),
                  color-stop(100%, rgba(255, 255, 255, 0))
  );
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
}

.orange-button a {
  color: inherit;
  font-family: 'Open Sans', sans-serif !important;
}

.map-canvas {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.item:hover {
  border-color: rgba(0, 0, 0, 0.48) !important;
}

@media (max-width: 450px) {
  .tour-price-list table th,
  .tour-price-list table td {
    padding: 7px 8px 7px 8px;
  }
}

/*CMS*/

/*tickets*/
.openning-hours {
  margin: 0 auto;
  width: 200px;
  position: relative;
  top: 20px;
}

.mail-icon-tickets {
  width: 20px;
  position: relative;
  top: 5px;
  margin: 0 5px;
}

.green-link-tickets {
  text-decoration: none;
  color: #537654;
}

/*about*/
.about-us-page {
  width: 100% !important;
  height: auto !important;
}

.about-us-page img {
  width: 100%;
  height: auto;
  border: solid 1px #ccc;
}

.about-us-page .imagerow {
  max-width: 600px;
  margin: 0 auto;
}

.about-us-page .imagerow .col-md-4 {
  position: relative;
  min-height: 1px;
  width: 30%;
  float: left;
  margin-left: 3%;
}

.tickets-page li {
  padding: 11px 2px 4px;
  list-style: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

/*global CMS*/
.inner-cms-box strong {
  color: #287fb8;
}

/*modal*/
// @media (max-width: 908px) {
//   .ReactModal__Content {
//     width: calc(100% - 42px) !important;
//   }
// }

// @media (max-width: 992px) {
//   .ReactModal__Content {
//     top: 0 !important;
//     left: 0 !important;
//     transform: none !important;
//   }
// }

// .ReactModal__Content svg {
//   left: 50%;
//   position: relative;
//   margin-left: -55px;
// }

/*intruction*/
.instructions-wrapper {
  max-width: 600px;
  margin: 0 auto;
  margin-top: -20px;
}

.instructions-wrapper p table p:first-child,
.instructions-wrapper p table p {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.25em;
}

.instructions-wrapper p table td {
  padding: 0 !important;
  border-bottom: 1px solid #f2f2f2;
}

.instructions-wrapper p table {
  width: 100% !important;
}

.instructions-wrapper p table tr {
  border-bottom: 1px solid #f2f2f2;
}

.instructions-wrapper p table tr td:last-child {
  min-width: 100px !important;
}

.instructions-wrapper p table tr td:last-child {
  min-width: 100px !important;
}

.instructions-wrapper p table tr td:last-child p {
  text-align: right !important;
}

.instructions-wrapper.instructions-print-wrapper {
  max-width: 820px;
}

.instructions-print-info h1 span {
  color: #287fb8 !important;
  font-size: 18px !important;
}

.instructions-wrapper p h1,
.instructions-wrapper p h1 span,
.instructions-print-info h1 {
  font-weight: 400 !important;
  font-size: 18px !important;
  text-align: left !important;
  line-height: 23px !important;
  margin: 26px 0 2px !important;
  color: rgb(56, 102, 150) !important;
}

.instructions-print-info h1 strong {
  font-weight: 400 !important;
}

#content > div {
  min-height: auto !important;
}

.instructions-wrapper p h1 span strong,
.instructions-wrapper p h1 strong {
  font-weight: 400 !important;
}

.instructions-wrapper p p:first-child,
.instructions-wrapper p p:first-child span,
.instructions-wrapper p p:first-child span span,
.instructions-wrapper div p:first-child {
  font-family: Open Sans, sans-serif !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: rgb(56, 102, 150) !important;
  line-height: 27px;
  margin: 20px 0;
}

.instructions-wrapper span,
.instructions-wrapper p,
.instructions-wrapper p span {
  font-family: Open Sans, sans-serif !important;
  font-size: 15px !important;
  margin-top: 5px;
}

.instructions-wrapper p table p:first-child span,
.instructions-wrapper p table p span {
  font-size: 15px !important;
  margin: 10px !important;
  text-align: left !important;
  color: #333 !important;
  font-weight: 400 !important;
}

.instructions-wrapper p table p {
  text-align: left !important;
  margin: 7px 0 7px !important;
}

.instructions-wrapper .header {
  font-weight: 600;
  font-size: 20px;
  color: rgb(56, 102, 150);
  line-height: 27px;
  margin: 20px 0;
}

.instructions-wrapper span {
  line-height: 20px;
}

.instructions-wrapper p {
  margin-right: 5%;
}

.instructions-wrapper br {
  content: 'A' !important;
  display: block !important;
  margin: 0.5em 0 !important;
}

.instructions-wrapper img {
  width: 100%;
  height: auto;
}

.instructions-wrapper table {
  padding: 0 !important;
}

.instructions-wrapper li {
}

.tour-price-list-print th {
  border-bottom: solid 1px grey;
}

.fixed-tabs {
}

.fixed-tabs::before {
  content: '';
  position: absolute;
  top: 47px;
  left: -100%;
  width: 200%;
  height: 30px;
  background: white;
}

.print-sold-out:after {
  content: 'Vyprodáno';
  position: absolute;
  left: 40%;
  margin-top: 6px;
  color: red;
}

.sticking-gallery .sticky {
  margin-top: 248px;
}

.inline-text-wraper.with-margin .left-column {
  margin-right: 11% !important;
}

// .dialog-box .actions-box {
//   padding: 20px !important;
// }

.search .items-wrapper .item:last-of-type {
  border-bottom: none !important;
}

@media (max-width: 1200px) {
  /*.react-tabs [role=tablist] {
        background: transparent !important;
    }

    .react-tabs [role=tab] {
        background: rgb(237, 183, 0) !important;
        border-right: 1px solid #fff !important;
        border-bottom: 1px solid #fff !important;
    }

    body .react-tabs [role=tab][aria-selected=true] {
        background: rgb(237, 183, 0) !important;
    }*/
  .inline-text-wraper .left-column {
    display: block;
    float: left;
    width: 100%;
  }

  .inline-text-wraper.with-margin .left-column {
    margin-right: 8% !important;
    width: 92%;
    display: block;
    float: left;
  }
}

@media (max-width: 991px) {
  .inline-text-wraper .left-column,
  .inline-text-wraper.with-margin .left-column {
    display: inline;
    width: auto;
    margin: 0;
    float: right;
  }

  .image-gallery {
    width: 100%;
  }

  .fullscreen .image-gallery-swipe::before {
    display: none;
  }
}

@media (max-width: 768px) {
  .fixed-tabs::before {
    display: none;
  }

  .sticking-gallery .sticky {
    position: relative !important;
  }

  .sticking-gallery .sticky > div {
    margin-top: 0 !important;
  }

  .dialog-box {
    width: 96% !important;
  }

  .inline-text-wraper .left-column,
  .inline-text-wraper.with-margin .left-column {
    margin: 0 !important;
  }

  /* .sticking-gallery > div > div {
         padding: 0 !important;
         left: 0 !important;
     }*/
}

@media (max-width: 480px) {
  body .tour-tile {
    width: 100% !important;
  }

  .dialog-box {
    width: 100% !important;
    position: absolute !important;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh !important;
    transform: none !important;
  }

  .dialog-box > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 !important;
  }

  // .dialog-box .actions-box {
  //   position: absolute;
  //   padding: 15px !important;
  //   bottom: 0;
  // }

  // .dialog-box .title-box {
  //   font-size: 18px !important;
  //   padding: 20px !important;
  //   font-weight: 600 !important;
  //   border-bottom: 1px solid #f2f2f2 !important;
  // }
}

@media (min-width: 800px) {
  .instructions-wrapper .header {
    padding: 30px 0;
  }

  .instructions-wrapper .column {
  }
}

@media (min-width: 1200px) {
  .sticking-gallery {
    width: 550px;
    float: right;
  }
}

.fullscreen {
  padding: 10%;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .fullscreen {
    padding: 1%;
  }
}

.sticking-gallery {
  clear: both;
}

.image-gallery-content {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 2px;
}

.image-gallery-image {
  height: 370px;
  overflow: hidden;
  text-align: center;
  background: #212121;
}

.image-gallery-slide img {
  width: auto;
  height: 100%;
}

.image-gallery-thumbnails-wrapper {
  padding: 0px 11px;
}

.image-gallery-thumbnail {
  height: 63px;
  overflow: hidden;
}

.fullscreen .image-gallery-image {
  max-height: 600px;
  max-width: 900px;
  height: inherit;
  overflow: inherit;
  margin: 0 auto;
}

.fullscreen .image-gallery-swipe::before {
  content: 'Režim celé obrazovky ukončíte klávesou ESC';
  position: absolute;
  left: 50%;
  font-size: 18px;
  color: white;
  top: -50px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
}

.fullscreen img {
  width: 100%;
  height: auto;
}

.datepicker-together-traveling {
  top: 0px;
}

.datepicker-together-traveling input,
.datepicker-together-traveling div {
  color: #404040 !important;
}

.react-parallax .react-parallax-bgimage {
  transform: translate3d(-50%, -120px, 0px) !important;
}

.pricelist-sticker {
  padding: 3px 6px;
  margin: 2px;
  font-size: 0.9em;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  border: none !important;
}

.soldOut {
  background-color: #000;
}

.lastMinute {
  background-color: #ff0123;
}

.specialOffer {
  background-color: rgb(40, 127, 184);
}

.gm-style .gm-style-iw {
  height: 270px;
  width: 250px !important;
  top: 23px !important;
  margin-left: 11px;
}

.gm-style .gm-style-iw .gallery-wrapper {
  overflow: hidden;
  height: 150px;
}

.gm-style .gm-style-iw .gallery-wrapper #next-left,
.gm-style .gm-style-iw .gallery-wrapper #next-right {
  z-index: 2;
  top: 50px;
  left: 5px;
  position: absolute;
  background: #fff;
  padding: 1px;
  color: #287fb8;
  border-radius: 15px;
}

.gm-style .gm-style-iw .gallery-wrapper #next-right {
  right: 5px;
  left: inherit;
}

.gm-style .gm-style-iw .gallery-wrapper img {
  width: 100%;
  height: auto;
  max-width: inherit;
  min-height: 100%;
  left: 50%;
  top: 50%;
  position: relative;
  transform: translate(-50%, -50%);
}

.gm-style .tour-info-window {
  position: relative;
  height: 100%;
  padding-bottom: 18px;
}

.gm-style .tour-info-window h2 {
  font-size: 1.2em;
}

.gm-style .tour-info-window .price {
  position: absolute;
  left: 0;
  bottom: 0;
  font-weight: 500;
  font-size: 1.1em;
}

.card-header {
  padding: 15px;
}

.price {
  color: rgb(237, 183, 0);
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 0;
}

.price.subsidy {
  color: #b4cb40 !important;
}

.hp-buttons-wrapper {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;

  @media only screen and (max-width: 820px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}

.section-types-wrapper {
  width: 370px;
  height: 30px;
  position: relative;
  top: 0;
  margin: 0 auto;
  border-radius: 5px;

  @media only screen and (max-width: 1300px) {
    left: 71px;
  }

  @media only screen and (max-width: 1024px) {
    left: -39px;
    width: 329px;
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 850px) {
    left: 0;
    top: 82px;
  }

  @media only screen and (max-width: 768px) {
    top: 80px;
  }

  @media only screen and (max-width: 600px) {
    top: 58px;
    width: 100%;
  }


  a {
    padding: 7px 15px;
    line-height: 29px;
    color: white;
    font-weight: 600;
    border-radius: 0 0 5px 5px;
    cursor: pointer;
    margin: 5px;

    @media only screen and (max-width: 1300px) {
      padding: 7px 10px;
      margin: 2px;
    }

    @media only screen and (max-width: 768px) {
      padding: 7px 10px;
      font-size: 11px;
    }
  }
}