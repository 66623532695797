@import '../../styles/global/variables';
@import '../../styles/global/mixins';

.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal__content {
  position: relative;
  width: 90vw;
  max-height: 80vh;
  top: 10%;
  margin-inline: auto;
  background-color: map-get($grayscale-colors, white);
  padding: 2rem;
  border-radius: $global-radius;
  overflow: scroll;

  @include breakpoint(medium) {
    top: 20%;
    padding: 4.875rem;
  }

  @include breakpoint(large) {
    max-width: 70vw;
  }

  @include breakpoint(xlarge) {
    max-width: 50vw;
  }
}
