@import '../../../../../../styles/global/variables';
@import '../../../../../../styles/global/mixins';

.review {
  position: relative;
  padding-top: $global-margin * 2;

  @include breakpoint(medium) {
    padding-top: $global-margin * 3;
  }
}

.review__item {
	position: relative;
	padding-block: 1rem;
	border-bottom: 0.0625rem solid map-get($grayscale-colors, gray-200);

	@include breakpoint (medium){
		padding-block: 2rem;
	}
}