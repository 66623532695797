
.swiper-slide {
  height: auto !important;
  box-sizing: border-box;
}

.swiper-button-prev, 
.swiper-button-next {
  width: 3rem !important;
  height: 3rem !important;
  background-color: map-get($brand-colors, primary);
  border-radius: 50%;
}

.swiper-button-next::after, 
.swiper-button-prev::after {
  font-size: 1.25rem !important;
  color: map-get($grayscale-colors, white);
}

.swiper--similar {

  .swiper-button-prev, 
  .swiper-button-next {
    @media screen and (min-width: 64.125rem) {
      display: none;
    }
  }

  .swiper-slide {
    max-width: 23.75rem;
  }
} 