@import '../../../../../styles/global/variables';
@import '../../../../../styles/global/mixins';



.guarantee {
  position: relative;
  display: flex;
  border-top: 0.125rem solid map-get($brand-colors, primary);
  background-color: map-get($brand-colors, white);
  box-shadow: $global-shadow;
  margin-bottom: $global-margin * 2;
  padding: 1rem 1.5rem;
}

.guarantor__image {
  position: relative;
  margin-right: $global-margin;
  flex: 0 0 auto;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }
}

.guarantor__content {
	h2 {
		font-size: clamp(0.875rem, 1.8vw, 1rem);
		margin: 0;
	}
}