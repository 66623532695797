.object {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 0.0625rem solid map-get($grayscale-colors, gray-100);
  border-radius: $global-radius / 2;
  transition: $global-transition;
  cursor: pointer;

  &:hover {
    top: -$global-margin / 2;
    border-color: map-get($brand-colors, primary);
  }
}

.object__header {
  height: 16rem;
  border-radius: $global-radius / 2 $global-radius / 2 0 0;
  overflow: hidden;

  picture {
    display: block;
  }

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 4/3;
    max-height: 16rem;
  }
}

.object__body {
  padding: $global-margin * 1.5 $global-margin;

  h3 {
    font-size: clamp(1.125rem, 2vw, 1.25rem);
    margin-bottom: $global-margin / 2;
  }
}

.object__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: $global-margin;
  margin-top: auto;
  margin-inline: $global-margin;
  border-top: 0.0625rem solid map-get($grayscale-colors, gray-100);
}

.object__price {
  font-size: 1.5rem;
  line-height: 1.2;

  span {
    margin-right: 0.5rem;
  }
}