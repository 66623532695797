@import '../../../../../../styles/global/variables';
@import '../../../../../../styles/global/mixins';

.info {
  position: relative;
  padding: $global-margin * 2 $global-margin;
  background-color: map-get($grayscale-colors, white);
  border: $global-border;
  border-radius: $global-radius;
  margin-bottom: 1.5rem;

  @include breakpoint(medium) {
    padding: $global-margin * 2;
  }

  // button {
  //   position: absolute;
  //   bottom: -1.5rem;
  //   left: 2rem;
  //   right: 2rem;
  // }
}

.info__control {
  position: absolute;
  bottom: -3rem;
  left: 2rem;
  right: 2rem;
  text-align: center;

  button {
    width: 100%;
  }
}