@import '../../styles/global/variables';
@import '../../styles/global/mixins';

.banner {
  position: relative;
  border-radius: $global-radius;
  overflow: hidden;
}

.banner__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.banner__content {
  position: relative;
  padding: $global-margin * 3 $global-margin * 2;
  background: $global-gradient;

  @include breakpoint(medium) {
    padding: $global-margin * 3.25 $global-margin * 3.5;
  }

  p {
    max-width: 30rem;
  }
}