@import '../../../../styles/global/variables';
@import '../../../../styles/global/mixins';

.summary {
  position: relative;
}

.summary__item {
  padding: $global-margin;
  background-color: map-get($grayscale-colors, white);
  border: $global-border;
  border-radius: $global-radius;
  margin-bottom: $global-margin;

  @include breakpoint(large) {
    padding: calc(#{$global-margin} * 2);
  }
}