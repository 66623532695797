@import '../../../../../../styles/global/variables';
@import '../../../../../../styles/global/mixins';

.description {
  position: relative;
  padding-top: $global-margin * 2;
  line-height: 2;
  color: map-get($brand-colors, primary-highdark);  

  @include breakpoint(medium) {
    padding-top: $global-margin * 3;
  }

  strong,
  b {
    color: map-get($brand-colors, primary);
  }  

  li,
  p {
    line-height: 2;
    color: map-get($brand-colors, primary-highdark);

    strong,
    b {
      color: map-get($brand-colors, primary);
    }
  }

  * + h1,
  * + h2 {
    margin-top: $global-margin * 3;
  }

  h1, h2 {
    color: map-get($brand-colors, primary-highdark);
  }

  h1 {
    font-size: clamp(1.25rem, 2vw, 2rem);
    letter-spacing: -0.025rem;
  }
}