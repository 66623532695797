.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: map-get($grayscale-colors, white);
}

.loader__spinner {
  border: 0.375rem solid map-get($grayscale-colors, gray-100);
  border-top: 0.375rem solid map-get($brand-colors, primary);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}