@import '../../../../../styles/global/variables';
@import '../../../../../styles/global/mixins';

.tabs {
  position: relative;
  margin-top: -1.5rem;
}

.tabs__nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  border: $global-border;
  border-radius: 1.5rem;
  overflow: hidden;
  background-color: map-get($grayscale-colors, white);
  z-index: 2;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.tabs__item {
  flex: 1;
  border-radius: 0;

  + .tabs__item {
    border-top: $global-border;

    @include breakpoint(medium) {
      border-top: 0;
      border-left: $global-border;
    }
  }
}

.tabs__link {
  display: block;
  text-align: center;
  padding: 0.875rem 1rem;
  font-weight: map-get($global-weight, bold);
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: map-get($grayscale-colors, black);
  cursor: pointer;
  transition: $global-transition;
}

.tabs__link:hover,
.tabs__item[aria-selected="true"] .tabs__link {
  color: map-get($grayscale-colors, white);
  background-color: map-get($brand-colors, primary);
}
