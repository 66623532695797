@import '../../../../../../styles/global/variables';
@import '../../../../../../styles/global/mixins';

.departure {
  position: relative;
  padding-top: $global-margin * 2;

  @include breakpoint(medium) {
    padding-top: $global-margin * 3;
  }
}

.table {
  position: relative;
	// color: map-get($grayscale-colors, black);
	// background-color: transparent;
	// font-size: clamp(0.875rem, 1.8vw, 1rem);
	// font-weight: map-get($global-weight, semi);
}

.table__row {
  display: grid;
  gap: $global-margin;
  grid-template-columns: auto auto;

  > div {
    padding-block: $global-margin / 4;
  }
}