@import '../../../../../styles/global/variables';
@import '../../../../../styles/global/mixins';

$componentHeight: 3rem;
$componentBorder: 0.0625rem solid map-get($grayscale-colors, gray-100);
$componentRadius: 0.375rem;

.select {
  position: relative;
  cursor: pointer;
}

.select__field {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-inline: $componentHeight 1rem;  
  font-weight: map-get($global-weight, semi);
  height: $componentHeight;
  font-size: 0.875rem;
  border: $componentBorder;
  border-radius: $componentRadius;
  white-space: nowrap;
  overflow: hidden;

  svg {
    position: absolute;
    top: calc(50% - 0.75rem);
    left: 1rem;
  }
}

.select__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: map-get($grayscale-colors, white);
  z-index: 8;
  box-shadow: $global-shadow;
  border-radius: $componentRadius;
  overflow: auto;
  max-height: 20rem;

  div {
    display: flex;
    align-items: center;
    font-weight: map-get($global-weight, semi);
    font-size: 0.875rem;
    padding: 0.875rem 1rem;
    cursor: pointer;
    transition: $global-transition;

    &:hover {
      color: map-get($grayscale-colors, white);
      background-color: map-get($brand-colors, primary);
    }

    input {
      width: 1.25rem;
      height: 1.25rem;
      flex: 0 0 auto;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: map-get($grayscale-colors, white);
      background-size: 0.75rem;
      margin: 0;
      margin-right: 0.875rem;	
      appearance: none;
      border: $componentBorder;
      border-radius: $componentRadius;

      &:checked {
        background-color: map-get($brand-colors, primary);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
      }
    }

    + div {
      border-top: $componentBorder;
    }
  }
}

.sort {
  position: relative;
  width: 12.5rem;
}

.sort__button {
  position: relative;
  display: flex;
  align-items: center;
  height: $componentHeight;
  padding-inline: 1rem;
  font-weight: map-get($global-weight, semi);
  font-size: 0.875rem;
  line-height: 1.25rem;
  border: $componentBorder;
  border-radius: $componentRadius;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.708' height='16'%3E%3Cg data-name='Icon'%3E%3Cpath data-name='965c06cd-0d24-47b9-86f4-50807f24a755' d='m1.414 3.97 7.94 7.94 7.94-7.94' fill='none' stroke='%230049c6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
  cursor: pointer;

  span {
    color: map-get($brand-colors, primary);
  }
}

.sort__dropdown {
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  width: 100%;
  background-color: map-get($grayscale-colors, white);
  z-index: 8;
  box-shadow: $global-shadow;
  border-radius: $componentRadius;
  overflow: hidden;
  
  ul > li {
    font-weight: map-get($global-weight, semi);
    font-size: 0.875rem;
    padding: 0.875rem 1rem;
    cursor: pointer;
    transition: $global-transition;

    &:hover {
      color: map-get($grayscale-colors, white);
      background-color: map-get($brand-colors, primary);
    }    
  }
}