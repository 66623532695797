@import '../../../styles/global/variables';
@import '../../../styles/global/mixins';

.block {
	position: relative;
	padding: $global-margin * 2 $global-margin;
	border: 0.0625rem solid map-get($grayscale-colors, gray-200);
	box-shadow: $global-shadow;
	border-radius: $global-radius/2;
  background-color: map-get($grayscale-colors, white);
  margin-top: calc(#{$global-margin} * (-3));
  z-index: 8;

	@include breakpoint(large) {
    padding: $global-margin * 4.25 $global-margin * 3.5;
    margin-top: calc(#{$global-margin} * (-6));
  }
}
