@import '../../styles/global/variables';
@import '../../styles/global/mixins';

.widget {
	position: relative;
	border-radius: $global-radius / 2;
  overflow: hidden;
  display: flex;
  min-height: 17.5rem;
  text-decoration: none;
  top: 0;
  transition: $global-transition;
  cursor: pointer;

  &:hover {
    top: -$global-margin / 2;
  }
}

.widget__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.widget__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  width: 100%;
	position: relative;
  padding: $global-margin * 2 $global-margin * 1.5;
  background: $global-gradient;

	h3 {
		font-size: clamp(1.375rem, 2vw, 1.5rem);
		margin-top: 0;
	}

	//p{
	//	max-width: 29.6875rem;
	//}
}